<template>
	<el-dialog
		:title="titleDialog"
		:visible="showDialog"
		@close="close"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		@opened="create"
		width="60%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row dense>
				<v-col cols="12" xl="3" offset-xl="5">
					<div class="form-group" :class="{ 'error--text': errors.date_of_reference }">
						<label>Fecha de referencia</label>
						<el-date-picker
							v-model="form.date_of_reference"
							type="date"
							:clearable="false"
							value-format="yyyy-MM-dd"
							format="dd/MM/yyyy"
							@change="changeDateOfReference"
							:picker-options="pickerOptions"
						>
						</el-date-picker>
						<small
							class="error--text"
							v-if="errors.date_of_reference"
							v-text="errors.date_of_reference[0]"
						></small>
					</div>
				</v-col>
				<v-col cols="12" xl="4">
					<el-button
						type="info"
						@click.prevent="clickSearch"
						class="mt-5 btn-block"
						:loading="loading_search"
					>
						<template v-if="loading_search">
							Buscando...
						</template>
						<template v-else>
							Buscar
						</template>
					</el-button>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12">
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th>N°</th>
									<th>Número</th>
									<th class="text-center">M.</th>
									<th class="text-right">T.Gravado</th>
									<th class="text-right">T.Igv</th>
									<th class="text-right">Total</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(row, index) in form.documents" :key="index">
									<td>{{ index + 1 }}</td>
									<td>
										{{ row.number }} -
										<small v-text="row.document_type_description"></small><br />
										<small
											v-if="row.affected_document"
											v-text="row.affected_document"
										></small>
									</td>
									<td class="text-center">{{ row.currency_type_id }}</td>
									<td class="text-right">{{ row.total_taxed }}</td>
									<td class="text-right">{{ row.total_igv }}</td>
									<td class="text-right">{{ row.total }}</td>
									<td class="text-right">
										<v-btn
											color="error"
											small
											@click.prevent="clickRemoveDocument(index)"
										>
											<v-icon>mdi-delete</v-icon>
										</v-btn>
									</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</v-col>
			</v-row>
			<div class="form-actions text-right pt-3 mb-3">
				<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
				<el-button
					type="primary"
					native-type="submit"
					:loading="loading_submit"
					v-if="form.documents && form.documents.length > 0"
				>
					<template v-if="loading_submit">
						Guardando...
					</template>
					<template v-else>
						Guardar
					</template>
				</el-button>
			</div>
		</form>
	</el-dialog>
</template>
<script>
import dayjs from 'dayjs';

export default {
	props: ['showDialog'],
	data() {
		return {
			loading_submit: false,
			loading_search: false,
			titleDialog: null,
			resource: 'summaries',
			errors: {},
			form: {},
			pickerOptions: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	methods: {
		initForm() {
			(this.loading_submit = false), (this.loading_search = false), (this.errors = {});
			this.form = {
				id: null,
				summary_status_type_id: '1',
				date_of_issue: null,
				date_of_reference: dayjs().format('YYYY-MM-DD'),
				documents: null,
			};
		},
		create() {
			this.titleDialog = 'Registrar resumen';
			this.initForm();
			this.changeDateOfReference();
		},
		clickSearch() {
			this.loading_search = true;
			this.$http
				.post(`/${this.resource}/documents`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.form.documents = response.data.data;
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				})
				.then(() => {
					this.loading_search = false;
				});
		},
		changeDateOfReference() {
			this.form.documents = [];
		},
		clickRemoveDocument(index) {
			this.form.documents.splice(index, 1);
		},
		submit() {
			this.loading_submit = true;
			this.$http
				.post(`${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading_submit = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
